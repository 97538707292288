<template>
    <div class="modal fade" id="facilityFormModal" tabindex="-1" aria-labelledby="facilityFormModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <form @submit="on_submit" class="modal-content" novalidate>
                <div class="modal-header">
                    <div class="modal-title" id="facilityFormModalLabel">
                        <template v-if="is_new">Neue Einrichtung anlegen</template>
                        <template v-else>Einrichtung "{{ number }}" bearbeiten</template>
                    </div>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Schließen"></button>
                </div>

                <div class="modal-body">
                    <div class="alert alert-danger" :class="{'d-none': !is_validated || errors.length == 0}" role="alert">
                        Bitte prüfen Sie Ihre Eingaben.
                    </div>

                    <div v-if="is_new || facility.is_new" class="form-floating mb-3">
                        <input v-model="start_date" :class="[valid_class('start_date')]" :readonly="readonly" class="form-control" type="date" placeholder="Inbetriebnahme der Einrichtung bzw. des Dienstes" id="inputFacilityStartDate">
                        <label for="inputFacilityStartDate">Inbetriebnahme der Einrichtung bzw. des Dienstes</label>
                        <div class="invalid-feedback">Bitte wählen Sie ein Startdatum.</div>
                    </div>

                    <div class="form-floating mb-3">
                        <input v-model="name" :class="[valid_class('name')]" :readonly="readonly" class="form-control" type="text" placeholder="Name bzw. Eigenbezeichnung der Einrichtung bzw. des Dienstes" id="inputFacilityName">
                        <label for="inputFacilityName">Name bzw. Eigenbezeichnung der Einrichtung bzw. des Dienstes</label>
                        <div class="invalid-feedback">Bitte geben Sie einen Namen für die Einrichtung an.</div>
                    </div>

                    <div class="form-floating mb-3">
                        <select v-model="type_id" :class="[valid_class('type_id')]" :disabled="readonly" class="form-select" id="inputFacilityType" aria-label="Einrichtungsart">
                            <option v-if="is_new || facility.is_new" value="-1">Unbekannt</option>
                            <optgroup v-if="facility_types_scoped.fb.length > 0" label="aus Ihrem Fachbereich">
                                <option v-for="facility_type in facility_types_scoped.fb" :key="facility_type.id" :value="facility_type.id">{{ facility_type.name }} (EA.{{ facility_type.number }})</option>
                            </optgroup>

                            <optgroup v-if="facility_types_scoped.fg.length > 0" label="aus Ihrer Fachgruppe">
                                <option v-for="facility_type in facility_types_scoped.fg" :key="facility_type.id" :value="facility_type.id">{{ facility_type.name }} (EA.{{ facility_type.number }})</option>
                            </optgroup>

                            <optgroup v-if="facility_types_scoped.other.length > 0" label="weitere">
                                <option v-for="facility_type in facility_types_scoped.other" :key="facility_type.id" :value="facility_type.id">{{ facility_type.name }} (EA.{{ facility_type.number }})</option>
                            </optgroup>
                        </select>
                        <label for="inputFacilityType">Einrichtungsart</label>
                        <div class="invalid-feedback">Bitte wählen Sie eine Einrichtungsart aus.</div>
                    </div>

                    <!--<div class="form-floating mb-3">
                        <input class="form-control" type="text" placeholder="Pauschale" id="inputFacilityFlatrate" :value="flatrate" disabled>
                        <label for="inputFacilityFlatrate">Pauschale</label>
                    </div>-->

                    <div class="form-check mb-3">
                        <input v-model="charge" :class="[valid_class('charge')]" type="checkbox" class="form-check-input" id="inputFacilityCharge" :disabled="readonly || !(is_new || facility.is_new)">
                        <label for="inputFacilityCharge" class="form-check-label">Einrichtung berechnen</label>
                        <div class="form-text">
                            Umsatz im Beitragsjahr der Einrichtung bzw. des Dienstes übersteigt 50.000,00 €
                            nicht und das Personal umfässt nicht mindestens eine Vollzeitstelle – unabhängig von der Finanzierung.
                        </div>
                    </div>

                    <div class="form-floating mb-3" v-if="(is_new || facility.is_new) && !charge">
                        <textarea v-model="no_charge_reason" :class="[valid_class('no_charge_reason')]" class="form-control" id="inputFacilityNoChargeReason" placeholder="Begründung"></textarea>
                        <label for="inputFacilityNoChargeReason">Begründung</label>
                        <div class="form-text" :class="{'form-text': !errors.includes('no_charge_reason'),'invalid-feedback': errors.includes('no_charge_reason') }">
                            Bitte geben Sie eine Begründung dafür an, dass die Einrichtung nicht berechnet wird.
                        </div>
                    </div>

                    <div class="form-floating mb-3">
                        <input v-model="email" :class="[valid_class('email')]" :readonly="readonly" type="email" class="form-control" id="inputFacilityEmail" placeholder="E-Mail für Fachverteiler">
                        <label for="inputFacilityEmail">E-Mail für Fachverteiler</label>
                        <div class="invalid-feedback">Bitte geben Sie eine korrekte E-Mail Adresse an.</div>
                    </div>

                    <div class="form-floating mb-3">
                        <input v-model="street" :class="[valid_class('street')]" :readonly="readonly" class="form-control" type="text" placeholder="Straße und Hausnummer " id="inputFacilityStreet">
                        <label for="inputFacilityStreet">Straße und Hausnummer</label>
                    </div>

                    <div class="row mb-3">
                        <div class="col-sm-4 mb-3 mb-sm-0">
                            <div class="form-floating">
                                <input v-model="postal_code" :class="[valid_class('postal_code')]" :readonly="readonly" class="form-control" type="text" placeholder="PLZ" id="inputFacilityPostalCode">
                                <label for="inputFacilityPostalCode">PLZ</label>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-floating">
                                <input v-model="city" :class="[valid_class('city')]" :readonly="readonly" class="form-control" type="text" placeholder="Ort" id="inputFacilityCity">
                                <label for="inputFacilityCity">Ort</label>
                            </div>
                        </div>
                    </div>

                    <div class="form-floating">
                        <textarea v-model="comment" :class="[valid_class('comment')]" :readonly="readonly" class="form-control" placeholder="Kommentar" id="inputFacilityComment"></textarea>
                        <label for="inputFacilityComment">Kommentar</label>
                        <div class="invalid-feedback">Bitte geben Sie eine Erläuterung zu dieser Änderung an</div>
                    </div>
                </div>

                <div class="modal-footer">
                    <template v-if="!readonly">
                        <button class="btn btn-secondary me-auto" type="button" data-bs-dismiss="modal">Abbrechen</button>
                        <button class="btn btn-primary" type="submit" :disabled="!modified">Speichern</button>
                    </template>
                    <template v-else>
                        <button class="btn btn-secondary me-auto" type="button" data-bs-dismiss="modal">Schließen</button>
                    </template>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { Modal } from '@paris/pui/bs';

import { AddressChanges, Facility, FacilityChanges } from './types';
import { store_key } from './symbols';

export default {
    name: 'FacilityForm',

    setup() {
        const store = useStore(store_key);

        const readonly = computed(() => store.state.readonly);

        const is_new = ref(true);
        const number = ref('');

        const errors = ref<string[]>([]);
        const is_validated = ref(false);

        const start_date = ref('');

        const name = ref('');
        const type_id = ref<number>(null);
        const street = ref('');
        const postal_code = ref('');
        const city = ref('');
        const charge = ref(true);
        const no_charge_reason = ref('');

        const email = ref('');

        const comment = ref('');

        let active = false;
        let modal: Modal = null;

        const facility = ref<Facility>(null);

        const modified = computed(() => {
            if (is_new.value) return true;
            if (name.value.trim() !== facility.value.name.trim()) return true;
            if ((!facility.value.type && type_id.value > 0) || (facility.value.type && type_id.value !== facility.value.type.id)) return true;

            if (facility.value.address) {
                if (facility.value.address.address.trim() !== street.value.trim()) return true;
                if (facility.value.address.postal_code.trim() !== postal_code.value.trim()) return true;
                if (facility.value.address.city.trim() !== city.value.trim()) return true;
            }
            else {
                if (street.value && street.value.trim() !== '') return true;
                if (postal_code.value && postal_code.value.trim() !== '') return true;
                if (city.value && city.value.trim() !== '') return true;
            }

            if ((facility.value.email && facility.value.email.address.trim() !== email.value.trim()) || (!facility.value.email && email.value && email.value.trim() !== '')) {
                return true;
            }

            if (facility.value.comment) {
                if (!comment.value || comment.value.trim() !== facility.value.comment.trim()) return true;
            }
            else if (comment.value && comment.value.trim() !== '') return true;

            if (facility.value.is_new && start_date.value.trim() !== facility.value.start_date.trim()) {
                return true;
            }

            if (facility.value.is_new && charge.value !== facility.value.charge) {
                return true;
            }

            if (facility.value.is_new && ((facility.value.no_charge_reason && facility.value.no_charge_reason.trim() !== no_charge_reason.value.trim()) || (!facility.value.no_charge_reason && no_charge_reason.value.trim() !== ''))) {
                return true;
            }

            return false;
        });

        const facility_types_scoped = computed(() => store.state.facility_types_scoped);

        const flatrate = computed(() => {
            if (type_id.value && type_id.value > 0) {
                let _flatrate_id = 0;

                if (is_new.value || (!facility.value.type && type_id.value > 0) || type_id.value !== facility.value.type.id) {
                    _flatrate_id = store.state.facility_types[type_id.value].flatrate_id;
                }
                else {
                    _flatrate_id = facility.value.flatrate.id;
                }

                if (_flatrate_id && _flatrate_id > 0) {
                    return store.state.facility_flatrates[_flatrate_id].name;
                }
            }

            return null;
        });

        const validate = (): boolean => {
            errors.value = [];

            if (!name.value || name.value.trim() === '') {
                errors.value.push('name');
            }

            if (!type_id.value || !(type_id.value in store.state.facility_types || ((is_new.value || facility.value.is_new) && (type_id.value === -1)))) {
                errors.value.push('type_id');
            }

            if ((postal_code.value && postal_code.value.trim() !== '') || (city.value && city.value.trim() !== '') || (street.value && street.value.trim() !== '')) {
                if (!postal_code.value || postal_code.value.trim() === '' || !postal_code.value.match(/^\d{5}$/)) {
                    errors.value.push('postal_code');
                }

                if (!city.value || city.value.trim() === '') {
                    errors.value.push('city');
                }

                if (!street.value || street.value.trim() === '') {
                    errors.value.push('street');
                }
            }

            const emailInput: HTMLInputElement = document.querySelector('#inputFacilityEmail');
            if (email.value && email.value.trim() !== '' && !emailInput.validity.valid) {
                errors.value.push('email');
            }

            if (!is_new.value && !facility.value.is_new && (!comment.value || comment.value.trim() === '')) {
                errors.value.push('comment');
            }

            if ((is_new.value || facility.value.is_new) && (!start_date.value || start_date.value.trim() === '')) {
                errors.value.push('start_date');
            }

            if ((is_new.value || facility.value.is_new) && (!charge.value && no_charge_reason.value.trim() === '')) {
                errors.value.push('no_charge_reason');
            }

            is_validated.value = true;

            return errors.value.length === 0;
        };

        const on_submit = (event: Event): void => {
            event.preventDefault();

            if (modified.value && validate()) {
                if (is_new.value) {
                    const changes: FacilityChanges = {
                        name: name.value.trim(),
                        type_id: type_id.value,
                        comment: comment.value.trim(),
                        start_date: start_date.value.trim(),
                        charge: charge.value,
                        no_charge_reason: !charge.value ? no_charge_reason.value.trim() : '',
                    };

                    if ((street.value && street.value.trim() !== '') || (postal_code.value && postal_code.value.trim() !== '') || (city.value && city.value.trim() !== '')) {
                        changes.address = {
                            address: street.value.trim(),
                            postal_code: postal_code.value.trim(),
                            city: city.value.trim(),
                        };
                    }

                    if (email.value && email.value.trim() !== '') {
                        changes.email = {
                            address: email.value.trim(),
                        };
                    }

                    store.dispatch('createFacility', { changes });
                }
                else {
                    const changes: FacilityChanges = {};
                    if (name.value.trim() !== facility.value.name.trim()) changes.name = name.value.trim();
                    if ((!facility.value.type && type_id.value > 0) || (facility.value.type && type_id.value !== facility.value.type.id)) changes.type_id = type_id.value;
                    if (comment.value && comment.value.trim() !== '') changes.comment = comment.value.trim();

                    if (facility.value.is_new) {
                        if (facility.value.start_date.trim() !== start_date.value.trim()) {
                            changes.start_date = start_date.value;
                        }

                        if (facility.value.charge !== charge.value) {
                            changes.charge = charge.value;

                            if (charge.value) {
                                changes.no_charge_reason = '';
                            }
                        }

                        if (!charge.value && ((facility.value.no_charge_reason && facility.value.no_charge_reason.trim() !== no_charge_reason.value.trim()) || (!facility.value.no_charge_reason && no_charge_reason.value.trim() !== ''))) {
                            changes.no_charge_reason = no_charge_reason.value.trim();
                        }
                    }

                    const address_changes: AddressChanges = {};
                    if (facility.value.address) {
                        if ((!postal_code.value || postal_code.value.trim() === '') && (!city.value || city.value.trim() === '') && (!street.value || street.value.trim() === '')) {
                            address_changes.delete = true;
                        }
                        else {
                            if (street.value.trim() !== facility.value.address.address.trim()) address_changes.address = street.value.trim();
                            if (postal_code.value.trim() !== facility.value.address.postal_code.trim()) address_changes.postal_code = postal_code.value.trim();
                            if (city.value.trim() !== facility.value.address.city.trim()) address_changes.city = city.value.trim();
                        }
                    }
                    else {
                        if (street.value && street.value.trim() !== '') address_changes.address = street.value.trim();
                        if (postal_code.value && postal_code.value.trim() !== '') address_changes.postal_code = postal_code.value.trim();
                        if (city.value && city.value.trim() !== '') address_changes.city = city.value.trim();
                    }

                    const email_changes: AddressChanges = {};
                    if (facility.value.email) {
                        if (!email.value || email.value.trim() === '') {
                            email_changes.delete = true;
                        }
                        else if (email.value.trim() !== facility.value.email.address.trim()) {
                            email_changes.address = email.value.trim();
                        }
                    }
                    else if (email.value && email.value.trim() !== '') {
                        email_changes.address = email.value.trim();
                    }

                    if (Object.keys(address_changes).length > 0) {
                        changes.address = address_changes;
                    }

                    if (Object.keys(email_changes).length > 0) {
                        changes.email = email_changes;
                    }

                    store.dispatch('updateFacility', {
                        facility_id: facility.value.id,
                        changes,
                    });
                }

                modal.hide();
            }
        };

        const on_finished = () => {
            is_new.value = true;

            facility.value = null;
            start_date.value = '';
            name.value = '';
            number.value = '';
            type_id.value = null;

            street.value = '';
            postal_code.value = '';
            city.value = '';

            email.value = '';

            comment.value = '';

            charge.value = true;
            no_charge_reason.value = '';

            errors.value = [];
            is_validated.value = false;

            active = false;
        };

        const valid_class = (field: string): string => {
            if (is_validated.value) {
                if (errors.value.includes(field)) {
                    return 'is-invalid';
                }
                return 'is-valid';
            }

            return '';
        };

        const open = (_facility?: Facility): void => {
            if (active) {
                console.warn('tried to open already active facility form modal');
                return;
            }

            if (_facility) {
                is_new.value = false;

                facility.value = _facility;
                start_date.value = _facility.start_date;
                number.value = _facility.number;
                name.value = _facility.name;
                type_id.value = (_facility.type ? _facility.type.id : -1);
                charge.value = _facility.charge;
                no_charge_reason.value = _facility.no_charge_reason ? _facility.no_charge_reason : '';

                if (_facility.address) {
                    street.value = _facility.address.address;
                    postal_code.value = _facility.address.postal_code;
                    city.value = _facility.address.city;
                }

                if (_facility.email) {
                    email.value = _facility.email.address;
                }

                comment.value = facility.value.comment ? facility.value.comment : '';
            }

            modal.show();
            active = true;
        };

        onMounted((): void => {
            const modalElement = document.querySelector('#facilityFormModal');

            modal = new Modal(modalElement);

            modalElement.addEventListener('hidden.bs.modal', on_finished);
        });

        store.dispatch('loadFacilityTypes');
        store.dispatch('loadFacilityFlatrates');

        return {
            charge,
            city,
            comment,
            email,
            errors,
            facility,
            facility_types_scoped,
            flatrate,
            is_new,
            is_validated,
            modified,
            name,
            no_charge_reason,
            number,
            on_finished,
            on_submit,
            open,
            postal_code,
            readonly,
            start_date,
            street,
            type_id,
            valid_class,
        };
    },
};
</script>

<style lang="scss" scoped>
    #inputFacilityComment {
        height: 100px;
    }
</style>
