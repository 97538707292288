import { axios, AxiosRequestConfig } from '@paris/pui/utils/ajax';
import { createApp } from 'vue';
import { PUI } from '@paris/pui';
import format from '@paris/pui/utils/format';
// eslint-disable-next-line import/no-cycle
import { FacilityList, store, store_key } from '../components/facility_list';
import { Controller } from '../types';

export function get_axios_config(_config?: AxiosRequestConfig): AxiosRequestConfig {
    const config = _config || {};

    const current_url = new URL(window.location.toString());
    const base_path = current_url.pathname.match(/^(\/eingabe\/\d+)\//)[1];
    config.baseURL = `${current_url.origin}${base_path}`;

    config.params = config.params ? config.params : {};

    if (current_url.searchParams.has('wizard_id')) {
        config.params.wizard_id = current_url.searchParams.get('wizard_id');
    }

    return config;
}

export const invoices_wizard: Controller = {
    step_facilities() {
        PUI.ready(() => {
            const mount_point: HTMLDivElement = document.querySelector('#facilityList');
            const { title, readonly } = mount_point.dataset;

            const app = createApp(FacilityList, {
                title,
                readonly: readonly === 'true',
            });

            app.config.globalProperties.format = format;
            app.use(store, store_key);
            app.mount(mount_point);
        });
    },

    step_pkz_bgw() {
        PUI.ready(() => {
            const container: HTMLDivElement = document.querySelector('#inputBGWUploadContainer');
            const file_input: HTMLInputElement = container.querySelector('#inputBGWUpload');
            const file_button: HTMLButtonElement = container.querySelector('#inputBGWUploadButton');
            const file_text: HTMLInputElement = container.querySelector('#inputBGWUploadText');

            const bgw_skip_input: HTMLInputElement = document.querySelector('#inputBGWSkip');
            const no_paid_employees_input: HTMLInputElement = document.querySelector('#inputNoPaidEmployees');

            // file input
            const filename_saved = container.dataset.fname;

            const set_filename = () => {
                if (file_input.files.length > 0) {
                    file_text.value = file_input.files[0].name;
                }
                else if (filename_saved !== '') {
                    file_text.value = filename_saved;
                }
                else {
                    file_text.value = 'keine Datei ausgewählt';
                }
            };

            file_button.addEventListener('click', () => file_input.click());
            file_text.addEventListener('click', () => file_input.click());

            file_input.addEventListener('change', () => {
                set_filename();

                if (bgw_skip_input || no_paid_employees_input) {
                    if (bgw_skip_input) bgw_skip_input.parentElement.parentElement.parentElement.remove();
                    if (no_paid_employees_input) no_paid_employees_input.parentElement.parentElement.parentElement.remove();

                    file_text.classList.remove('is-invalid');
                    file_input.form.classList.add('was-validated');
                }
            });

            set_filename();

            // delete file
            const delete_button: HTMLButtonElement = container.querySelector('#inputBGWUploadDeleteButton');
            if (delete_button) {
                delete_button.addEventListener('click', () => {
                    const config = get_axios_config();
                    axios.delete('/bgw_file/', config).then(() => window.location.reload());
                });
            }

            // skip file reason

            /* eslint-disable no-param-reassign */
            const skip_file_reason_listener = (current_element: HTMLInputElement, other_element: HTMLInputElement) => {
                other_element.required = !current_element.checked;
                other_element.disabled = current_element.checked;
            };
            /* eslint-enable no-param-reassign */

            bgw_skip_input.addEventListener('change', () => skip_file_reason_listener(bgw_skip_input, no_paid_employees_input));
            no_paid_employees_input.addEventListener('change', () => skip_file_reason_listener(no_paid_employees_input, bgw_skip_input));
        });
    },
};
