import { render } from "./facility_list.vue?vue&type=template&id=4cdac69f"
import script from "./facility_list.vue?vue&type=script&lang=ts"
export * from "./facility_list.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4cdac69f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4cdac69f', script)) {
    api.reload('4cdac69f', script)
  }
  
  module.hot.accept("./facility_list.vue?vue&type=template&id=4cdac69f", () => {
    api.rerender('4cdac69f', render)
  })

}

script.__file = "assets/beitrag/components/facility_list/facility_list.vue"

export default script