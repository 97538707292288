<template>
    <div class="card">
        <h2 class="card-header">{{ title }}</h2>

        <div v-if="!readonly" class="btn-toolbar" role="toolbar" aria-label="Toolbar: Einrichtungen">
            <button @click="create" type="button" class="btn btn-sm btn-success me-1">Anlegen</button>
            <!--<button @click="undo_all" type="button" class="btn btn-sm btn-secondary">Änderungen verwerfen</button>-->
        </div>

        <div v-if="facilities.length > 0" class="table-responsive-lg">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Nummer</th>
                        <th scope="col">Name der Einrichtung / des angebotenen Dienstes</th>
                        <th scope="col">Einrichtungsart</th>
                        <th scope="col" colspan="2">Pauschale</th>
                        <th scope="col">Berechnet</th>
                        <th scope="col">Anschrift</th>
                        <th scope="col" class="pui-actions" aria-hidden="true"></th>
                    </tr>
                </thead>
                <tbody>
                    <facility v-for="facility in facilities" :key="facility.id" :facility="facility" />
                </tbody>
            </table>
        </div>

        <div v-else class="card-body">
            <p class="card-text">
                <p class="fw-bold">Hinweis:</p>

                <p class="mb-0">
                    Einrichtungen sind Angebote oder Dienstleistungen von Mitgliedsorganisationen - bspw. ein Wohnangebot oder die Beratungsstelle eines Vereines. Nicht der Verein selbst!<br>
                    Bei Organisationen, die keine eigenen Angebote oder Dienstleistungen unterhalten, sind hier keine Einrichtungen hinterlegt.
                </p>
            </p>
        </div>
    </div>

    <confirm-dialog ref="confirm_dialog" />
    <facility-delete-dialog ref="facility_delete_dialog" />
    <facility-form ref="facility_form" />
</template>

<script lang="ts">
import { computed, provide, ref } from 'vue';
import { useStore } from 'vuex';

import { sort_by_value } from '@paris/pui/utils/collections';
import {
    confirm_key, facility_delete_dialog_key, facility_form_key, store_key,
} from './symbols';

import { ConfirmDialog } from '../confirm_dialog';
import Facility from './facility.vue';
import FacilityDeleteDialog from './facility_delete_dialog.vue';
import FacilityForm from './facility_form.vue';

export default {
    name: 'FacilityList',

    components: {
        ConfirmDialog,
        Facility,
        FacilityDeleteDialog,
        FacilityForm,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        readonly: Boolean,
    },

    setup(props) {
        const store = useStore(store_key);

        store.commit('setReadonly', props.readonly);

        const facilities = computed(() => sort_by_value(store.state.facilities, 'number'));

        const confirm_dialog = ref<typeof ConfirmDialog>();
        const facility_delete_dialog = ref<typeof FacilityDeleteDialog>();
        const facility_form = ref<typeof FacilityForm>();

        const create = () => {
            facility_form.value.open();
        };

        const undo_all = () => {
            confirm_dialog.value.open({
                message: 'Sind Sie sicher, dass Sie alle Änderungen an den Einrichtungen rückgängig machen wollen?',
                confirmed: () => {
                    store.dispatch('reloadFacilities', { force: true });
                },
            });
        };

        provide(confirm_key, confirm_dialog);
        provide(facility_delete_dialog_key, facility_delete_dialog);
        provide(facility_form_key, facility_form);

        store.dispatch('reloadFacilities');

        return {
            confirm_dialog,
            create,
            facilities,
            facility_delete_dialog,
            facility_form,
            undo_all,
        };
    },
};
</script>
