/* eslint-disable max-classes-per-file */
import { axios, AxiosResponse } from '@paris/pui/utils/ajax';
import { IndexedList } from '@paris/pui/types';
import { get_axios_config } from '../../controllers/invoice_wizard';

import {
    Facility, FacilityChanges, FacilityFlatrate, FacilityType,
} from './types';

export class facilities {
    static load(force: boolean = false): Promise<AxiosResponse<Facility[]>> {
        const params: { force_reload?: boolean } = {};

        if (force) {
            params.force_reload = true;
        }

        const config = get_axios_config({
            params,
        });

        return axios.get('/facilities/', config);
    }

    static create(changes: FacilityChanges): Promise<AxiosResponse<Facility[]>> {
        const config = get_axios_config();

        return axios.post('/facilities/', changes, config);
    }

    static update(facility_id: number, changes: FacilityChanges): Promise<AxiosResponse<Facility[]>> {
        const config = get_axios_config();

        return axios.patch(`/facilities/${facility_id}/`, changes, config);
    }

    static delete(facility_id: number, comment: string = null, end_date: string = null): Promise<AxiosResponse<Facility[]>> {
        const params: any = {};

        if (comment) {
            params.comment = comment;
        }

        if (end_date) {
            params.end_date = end_date;
        }

        const config = get_axios_config({ params });

        return axios.delete(`/facilities/${facility_id}/`, config);
    }

    static reload(facility_id: number): Promise<AxiosResponse<Facility[]>> {
        const config = get_axios_config();

        return axios.get(`/facilities/${facility_id}/reload/`, config);
    }
}

export class facilityTypes {
    static load(): Promise<AxiosResponse<Array<FacilityType>>> {
        const config = get_axios_config();

        return axios.get('/facility_types/', config);
    }
}

export class facilityFlatrates {
    static load(): Promise<AxiosResponse<IndexedList<FacilityFlatrate>>> {
        const config = get_axios_config();

        return axios.get('/facility_flatrates/', config);
    }
}
