import { ControllerList } from '../types';
import { invoices_wizard } from './invoice_wizard';

export const controllers: ControllerList = {
    invoices_wizard,
};

export const run = () => {
    const page = document.body.dataset.puiPage;
    const page_parts = page.split('#');
    const controller_name: string = page_parts[0];
    const action_name: string = page_parts[1];

    if (controller_name in controllers && action_name in controllers[controller_name]) {
        const runner = controllers[controller_name][action_name];
        runner();
    }
};
