import { InjectionKey, Ref } from 'vue';
import { Store } from 'vuex';

import { State } from './types';
import { ConfirmDialog } from '../confirm_dialog';
import FacilityDeleteDialog from './facility_delete_dialog.vue';
import FacilityForm from './facility_form.vue';

export const store_key: InjectionKey<Store<State>> = Symbol('Store');

export const confirm_key: InjectionKey<Ref<typeof ConfirmDialog>> = Symbol('ConfirmDialog');
export const facility_delete_dialog_key: InjectionKey<Ref<typeof FacilityDeleteDialog>> = Symbol('FacilityDeleteDialog');
export const facility_form_key: InjectionKey<Ref<typeof FacilityForm>> = Symbol('FacilityForm');
