import { FormValidation, MailToFix } from '@paris/pui';

import { run } from './controllers';

require('@/scss/beitrag.scss');

FormValidation.init();
MailToFix.init();

run();
