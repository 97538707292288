import { render } from "./confirm_dialog.vue?vue&type=template&id=9bc8efee"
import script from "./confirm_dialog.vue?vue&type=script&lang=ts"
export * from "./confirm_dialog.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9bc8efee"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9bc8efee', script)) {
    api.reload('9bc8efee', script)
  }
  
  module.hot.accept("./confirm_dialog.vue?vue&type=template&id=9bc8efee", () => {
    api.rerender('9bc8efee', render)
  })

}

script.__file = "assets/beitrag/components/confirm_dialog/confirm_dialog.vue"

export default script