/* eslint-disable no-param-reassign */
import {
    ActionTree, createLogger, createStore, GetterTree, MutationTree,
} from 'vuex';
import { IndexedList } from '@paris/pui/types';
import { facilities as facilitiesAPI, facilityFlatrates as facilityFlatratesAPI, facilityTypes as facilityTypesAPI } from './api';
import {
    Facility, FacilityChanges, FacilityFlatrate, FacilityType, FacilityTypeListScoped, State,
} from './types';

const state: State = {
    facilities: [],
    facility_flatrates: {},
    facility_types: {},
    facility_types_scoped: {
        fb: [],
        fg: [],
        other: [],
    },
    readonly: false,
    wizard_id: '',
};

const getters: GetterTree<State, State> = {};

const actions: ActionTree<State, State> = {
    reloadFacilities({ commit }, payload: { force: boolean } = { force: false }) {
        if (payload.force === undefined) payload.force = false;

        facilitiesAPI.load(payload.force).then((response) => {
            commit('setFacilities', response.data);
        });
    },

    reloadFacility({ commit }, payload: { facility_id: number }) {
        facilitiesAPI.reload(payload.facility_id).then((response) => {
            commit('setFacilities', response.data);
        });
    },

    createFacility({ commit }, payload: { changes: FacilityChanges }) {
        facilitiesAPI.create(payload.changes).then((response) => {
            commit('setFacilities', response.data);
        });
    },

    updateFacility({ commit }, payload: { facility_id: number, changes: FacilityChanges }) {
        facilitiesAPI.update(payload.facility_id, payload.changes).then((response) => {
            commit('setFacilities', response.data);
        });
    },

    deleteFacility({ commit }, payload: { facility_id: number, comment: string, end_date?: string }) {
        facilitiesAPI.delete(payload.facility_id, payload.comment || null, payload.end_date || null).then((response) => {
            commit('setFacilities', response.data);
        });
    },

    loadFacilityFlatrates({ commit }) {
        facilityFlatratesAPI.load().then((response) => {
            commit('setFacilityFlatrates', response.data);
        });
    },

    loadFacilityTypes({ commit }) {
        facilityTypesAPI.load().then((response) => {
            commit('setFacilityTypes', response.data);
        });
    },
};

const mutations: MutationTree<State> = {
    setWizardId(_state, wizard_id) {
        _state.wizard_id = wizard_id;
    },

    setFacilities(_state, facilities: Facility[]) {
        _state.facilities = facilities;
    },

    setFacilityFlatrates(_state, facility_flatrates: IndexedList<FacilityFlatrate>) {
        _state.facility_flatrates = facility_flatrates;
    },

    setFacilityTypes(_state, facility_types: Array<FacilityType>) {
        _state.facility_types = Object.assign({}, ...facility_types.map((facility_type) => ({ [facility_type.id]: facility_type })));
        _state.facility_types_scoped = facility_types.reduce((obj: FacilityTypeListScoped, element: FacilityType): FacilityTypeListScoped => {
            obj[element.source].push(element);
            return obj;
        }, { fb: [], fg: [], other: [] });
    },

    setReadonly(_state, readonly: boolean) {
        _state.readonly = readonly;
    },
};

const debug = process.env.NODE_ENV !== 'production';

export const store = createStore<State>({
    strict: debug,
    plugins: debug ? [createLogger()] : [],

    state,

    actions,
    getters,
    mutations,
});
