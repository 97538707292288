<template>
    <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="label_id" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title" :id="label_id">Bestätigung</div>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Schließen"></button>
                </div>

                <div class="modal-body">
                    <div class="mb-3">
                        {{ message }}
                    </div>

                    <div class="form-floating mb-3">
                        <input v-model="end_date" :class="[valid_class('end_date')]" class="form-control" type="date" placeholder="Startdatum" id="inputFacilityEndDate">
                        <label for="inputFacilityEndDate">Enddatum</label>
                        <div class="invalid-feedback">Bitte wählen Sie ein Enddatum.</div>
                    </div>

                    <div class="form-floating">
                        <textarea v-model="reason" :class="[valid_class('reason')]" class="form-control" placeholder="Grund" id="inputReason" rows="1"></textarea>
                        <label for="inputReason">Grund</label>
                        <div class="invalid-feedback">Bitte geben Sie eine Erläuterung zur Beendigung dieser Einrichtung an!</div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-secondary me-auto" type="button" data-bs-dismiss="modal">Nein</button>
                    <button @click="on_confirm()" class="btn btn-primary" type="button">Ja</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, ref, onMounted } from 'vue';
import { Modal } from '@paris/pui/bs';

import { random_id } from '@paris/pui/utils/html';
import { DeleteDialogOptions } from './types';

export default {
    name: 'FacilityDeleteDialog',

    setup() {
        const id = random_id('dialog-');
        const label_id = `${id}Label`;

        const options = ref<DeleteDialogOptions>(null);

        const reason = ref('');
        const end_date = ref('');

        const message = computed(() => (options.value && options.value.message ? options.value.message : null));

        const errors = ref<string[]>([]);
        const is_validated = ref(false);

        let modal: Modal = null;

        let active = false;
        let confirmed = false;

        const validate = (): boolean => {
            errors.value = [];

            if (!reason.value || reason.value.trim() === '') {
                errors.value.push('reason');
            }

            if (!end_date.value || end_date.value.trim() === '') {
                errors.value.push('end_date');
            }

            is_validated.value = true;

            return errors.value.length === 0;
        };

        const on_confirm = () => {
            if (validate()) {
                confirmed = true;
                modal.hide();
            }
        };

        const on_finished = () => {
            let callback = null;
            const _reason = reason.value;
            const _end_date = end_date.value;

            if (confirmed) {
                callback = options.value.confirmed;
            }
            else {
                callback = options.value.cancelled;
            }

            options.value = null;

            reason.value = '';
            end_date.value = '';

            errors.value = [];
            is_validated.value = false;

            confirmed = false;
            active = false;

            if (callback) {
                callback({
                    reason: _reason,
                    end_date: _end_date,
                });
            }
        };

        const valid_class = (field: string): string => {
            if (is_validated.value) {
                if (errors.value.includes(field)) {
                    return 'is-invalid';
                }
                return 'is-valid';
            }
            return '';
        };

        // API
        const open = (_options: DeleteDialogOptions): void => {
            if (active) {
                console.warn(`tried to open already active facility delete dialog (message: ${message.value}`);
                return;
            }

            options.value = _options;

            modal.show();
            active = true;
        };

        onMounted(() => {
            const modalElement = document.querySelector(`#${id}`);

            modal = new Modal(modalElement);

            modalElement.addEventListener('hidden.bs.modal', on_finished);
        });

        return {
            end_date,
            id,
            label_id,
            message,
            on_confirm,
            open,
            options,
            reason,
            valid_class,
        };
    },
};
</script>
