<template>
    <tr :class="row_class">
        <td>{{ facility.number }}</td>
        <td>{{ facility.name }}</td>
        <td>
            <template v-if="facility.type">{{ facility.type.name }}</template>
            <template v-else>-</template>
        </td>
        <td>
            <template v-if="facility.flatrate">{{ facility.flatrate.name }}</template>
            <template v-else>-</template>
        </td>
        <td class="text-nowrap text-end font-monospace" :class="{'text-decoration-line-through': !facility.charge}">
            <template v-if="facility.flatrate">{{ format.money(facility.flatrate.fee) }}</template>
        </td>
        <td>
            <template v-if="facility.charge">Ja</template>
            <template v-else>Nein</template>
        </td>
        <td class="text-nowrap">
            <template v-if="facility.address">
                {{ facility.address.address }}<br>
                {{ facility.address.postal_code }} {{ facility.address.city }}
            </template>
        </td>
        <td class="pui-actions">
            <div class="btn-group">
                <template v-if="!readonly">
                    <button @click="undo" v-if="facility.is_deleted || facility.is_modified" type="button" class="btn btn-xs btn-outline-dark" title="rückgängig"><span class="fas fa-undo"></span></button>
                    <button @click="edit" v-if="!facility.is_deleted" type="button" class="btn btn-xs btn-outline-primary" title="bearbeiten"><span class="fas fa-pencil"></span></button>
                    <button @click="del" v-if="!facility.is_deleted" type="button" class="btn btn-xs btn-outline-danger" title="löschen"><span class="fas fa-trash"></span></button>
                </template>
                <template v-else>
                    <button @click="edit" v-if="!facility.is_deleted" type="button" class="btn btn-xs btn-outline-primary" title="anzeigen"><span class="fas fa-eye"></span></button>
                </template>
            </div>
        </td>
    </tr>
</template>

<script lang="ts">
import { PropType, computed, inject } from 'vue';
import { useStore } from 'vuex';

import {
    confirm_key, facility_delete_dialog_key, facility_form_key, store_key,
} from './symbols';
import { Facility, DeleteDialogConfirmPayload } from './types';

export default {
    name: 'Facility',

    props: {
        facility: {
            type: Object as PropType<Facility>,
            required: true,
        },
    },

    setup(props) {
        const store = useStore(store_key);

        const readonly = computed(() => store.state.readonly);

        const confirm_dialog = inject(confirm_key);
        const facility_delete_dialog = inject(facility_delete_dialog_key);
        const facility_form = inject(facility_form_key);

        const row_class = computed(() => {
            let _row_class = '';

            if (!props.facility.charge) _row_class = 'text-muted ';

            if (props.facility.is_deleted) {
                _row_class += 'table-deleted';
            }
            else if (props.facility.is_new) {
                _row_class += 'table-new';
            }
            else if (props.facility.is_modified) {
                _row_class += 'table-modified';
            }

            return _row_class;
        });

        const edit = () => {
            facility_form.value.open(props.facility);
        };

        const del = () => {
            if (props.facility.is_new) {
                confirm_dialog.value.open({
                    message: `Sind Sie sicher, dass Sie die Einrichtung "${props.facility.name}" (${props.facility.number}) löschen wollen?`,
                    confirmed: () => {
                        store.dispatch('deleteFacility', {
                            facility_id: props.facility.id,
                        });
                    },
                });
            }
            else {
                facility_delete_dialog.value.open({
                    message: `Sind Sie sicher, dass Sie die Einrichtung "${props.facility.name}" (${props.facility.number}) löschen wollen?`,
                    confirmed: (payload: DeleteDialogConfirmPayload) => {
                        store.dispatch('deleteFacility', {
                            facility_id: props.facility.id,
                            comment: payload.reason,
                            end_date: payload.end_date,
                        });
                    },
                });
            }
        };

        const undo = () => {
            confirm_dialog.value.open({
                message: `Sind Sie sicher, dass Sie die Änderungen an der Einrichtung "${props.facility.name}" (${props.facility.number}) rückgängig machen wollen?`,
                confirmed: () => {
                    store.dispatch('reloadFacility', { facility_id: props.facility.id });
                },
            });
        };

        return {
            del,
            edit,
            readonly,
            row_class,
            undo,
        };
    },
};
</script>

<style lang="scss" scoped>
    .table-deleted {
        text-decoration: line-through;
    }
</style>
